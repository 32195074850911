<template>
  <div class="gestion-organisme-new-th style-box-setting">
    <div class="style-box-setting-header">
      <v-row>
        <v-col cols="7">
          <div class="title-header">
            Gestion des organismes
          </div>
        </v-col>
        <v-col cols="2">
          <v-progress-circular
            class="ml-2"
            v-if="getOrganismeThNewLoading"
            indeterminate
            color="#704ad1"
          ></v-progress-circular>
        </v-col>
        <v-col cols="3">
          <div class="error-message ml-2 mr-2 mt-3">
            <div v-if="getOrganismeThNewError">
              <ul v-if="Array.isArray(getOrganismeThNewError)" class="mb-0">
                <li v-for="(e, index) in getOrganismeThNewError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <div v-else>{{ getOrganismeThNewError }}</div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-divider></v-divider>
    <div class="box-content">
      <div class="card-block-filter-setting pt-1 pb-1">
        <div class="right-block-filter">
          <v-select
            placeholder="Séléctionner"
            :items="[{ id: null, name: 'Tous' }, ...computedListOrganisme]"
            label="Organisme"
            v-model="filterOrganisme"
            outlined
            clearable
            item-text="name"
            item-value="id"
            @change="handleChangeOrganisme"
            :persistent-placeholder="true"
            dense
            :menu-props="{ bottom: true, offsetY: true }"
            color="#704ad1"
            item-color="#704ad1"
            class="mr-2 select-organisme"
          >
          </v-select>
          <v-autocomplete
            placeholder="Séléctionner"
            :items="[{ id: null, name: 'Tous' }, ...computedListGroup]"
            label="Groupe"
            v-model="filterGroup"
            outlined
            clearable
            item-text="name"
            item-value="id"
            @change="handleChangeGroup"
            :persistent-placeholder="true"
            dense
            :menu-props="{ bottom: true, offsetY: true }"
            color="#704ad1"
            item-color="#704ad1"
            class="mr-2 select-organisme"
          >
          </v-autocomplete>
          <v-menu :close-on-content-click="false" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateStartForSearch"
                label="Date début"
                outlined
                readonly
                hide-details
                clearable
                v-on="on"
                v-bind="attrs"
                :persistent-placeholder="true"
                placeholder="Sélectionner un date"
                prepend-inner-icon="mdi-calendar"
                color="#704ad1"
                class="mr-2"
                @click:clear="handleChangeDate_debut(null)"
              ></v-text-field>
            </template>

            <v-date-picker
              v-model="dateStartForSearch"
              @input="handleChangeDate_debut(dateStartForSearch)"
              no-title
              locale="fr"
              :allowed-dates="disabledStartDateFilter"
              color="#704ad1"
            >
            </v-date-picker>
          </v-menu>
          <v-menu :close-on-content-click="false" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateEndForSearch"
                label="Date début"
                outlined
                readonly
                hide-details
                clearable
                v-on="on"
                v-bind="attrs"
                :persistent-placeholder="true"
                placeholder="Sélectionner un date"
                prepend-inner-icon="mdi-calendar"
                color="#704ad1"
                class="mr-2"
                @click:clear="handleChangeDate_fin(null)"
              ></v-text-field>
            </template>

            <v-date-picker
              v-model="dateEndForSearch"
              @input="handleChangeDate_fin(dateEndForSearch)"
              no-title
              locale="fr"
              :allowed-dates="disabledEndDateFiltre"
              color="#704ad1"
            >
            </v-date-picker>
          </v-menu>
        </div>
        <div class="left-block-filter">
          <v-tooltip
            bottom
            color="#311B92"
            v-if="
              checkPermission('GOTHVO') &&
                computedCheckedRows &&
                computedCheckedRows.length > 0
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <div
                class="block-icon-setting text-center mr-1"
                v-on="on"
                v-bind="attrs"
                @click="handelBlokedRows"
              >
                <font-awesome-icon icon="lock" />
              </div>
            </template>
            <span>Verrouiller / Deverrouiller</span>
          </v-tooltip>
          <v-tooltip bottom color="#311B92">
            <template v-slot:activator="{ on, attrs }">
              <div
                class="block-icon-setting text-center mr-1"
                v-on="on"
                v-bind="attrs"
                v-if="checkPermission('GOTHAO')"
                @click="openModalAjouterOrganisme"
              >
                <font-awesome-icon icon="plus-circle" />
              </div>
            </template>
            <span> Ajouter un organisme</span>
          </v-tooltip>
        </div>
      </div>
    </div>
    <div>
      <div
        v-if="
          filterOrganisme !== null ||
            filterGroup !== null ||
            dateStartForSearch !== null ||
            dateEndForSearch !== null
        "
        class="block-filter-style pt-2"
      >
        <span class="phrase-style">Les filtres choisie sont : </span>
        <span class="item-filter-style" v-if="dateStartForSearch !== null"
          >Date début : {{ formateDateGetters(dateStartForSearch) }}</span
        >
        <span class="item-filter-style" v-if="dateEndForSearch !== null"
          >Date fin : {{ formateDateGetters(dateEndForSearch) }}</span
        >
        <span v-if="filterGroup !== null" class="item-filter-style"
          >Groupe : {{ computedFilterGroup }}</span
        >
        <span v-if="filterOrganisme !== null" class="item-filter-style"
          >Organisme : {{ computedFilterOrganisme }}</span
        >
      </div>
    </div>
    <div class="table-gestion-content">
      <v-data-table
        v-model="selectedTable"
        :headers="fields"
        :items="getOrganismeThNew"
        class="table-gestion"
        :class="{
          'show-filter-organisme':
            filterOrganisme !== null ||
            filterGroup !== null ||
            dateStartForSearch !== null ||
            dateEndForSearch !== null
        }"
        hide-default-footer
        :calculate-widths="false"
        :fixed-header="true"
        :items-per-page="perPage"
        hide-action
        :disable-sort="true"
        :no-data-text="
          getOrganismeThNewLoading
            ? 'Chargement en cours ...'
            : `Il n'y a aucun enregistrement à afficher`
        "
      >
        <template v-slot:[`header.check_all`]="{ header }">
          <td>
            <div v-if="header.value === 'check_all'">
              <v-checkbox
                class="check-header-organisme"
                v-model="checkAll"
                @change="checkAllFunction"
                color="#704ad1"
                :true-value="true"
                :false-value="false"
                hide-details
              ></v-checkbox>
            </div></td
        ></template>
        <template v-slot:[`item.check_all`]="{ item }">
          <td class="custom-cell">
            <v-checkbox
              class="check-body-organisme"
              v-model="item.check"
              @change="checkOneFunction(item.check)"
              color="#704ad1"
              :true-value="true"
              :false-value="false"
              hide-details
            ></v-checkbox>
          </td>
        </template>
        <template v-slot:[`item.lock_icon`]="{ item }">
          <td class="custom-cell">
            <font-awesome-icon
              class="icon-color-cdg"
              v-if="item.locked === 1"
              icon="lock"
            />
            <font-awesome-icon
              class="icon-color-cdg"
              v-if="item.locked === 0"
              icon="lock-open"
            />
          </td>
        </template>
        <template v-slot:[`item.organisme`]="{ item }">
          <td class="custom-cell">{{ item.organisme.name }}</td>
        </template>
        <template v-slot:[`item.group`]="{ item }">
          <td class="custom-cell">{{ item.group.name }}</td>
        </template>
        <template v-slot:[`item.date_debut`]="{ item }">
          <td class="custom-cell">{{ item.date_debut }}</td>
        </template>
        <template v-slot:[`item.date_fin`]="{ item }">
          <td class="custom-cell">{{ item.date_fin ? item.date_fin : '-' }}</td>
        </template>
        <template v-slot:[`item.coef_prime_installateur`]="{ item }">
          <td class="custom-cell">{{ item.coef_prime_installateur }}</td>
        </template>
        <template v-slot:[`item.coef_commercial_prime`]="{ item }">
          <td class="custom-cell">{{ item.coef_commercial_prime }}</td>
        </template>
        <template v-slot:[`item.coef_commercial_group`]="{ item }">
          <td class="custom-cell">{{ item.coef_commercial_group }}</td>
        </template>
        <template v-slot:[`item.coef_surprime_group`]="{ item }">
          <td class="custom-cell">{{ item.coef_surprime_group }}</td>
        </template>
        <template
          v-slot:[`item.coef_commission_gestion_dossier_administrative`]="{
            item
          }"
        >
          <td class="custom-cell">
            {{ item.coef_commission_gestion_dossier_administrative }}
          </td>
        </template>

        <template v-slot:[`item.Actions`]="{ item }">
          <td class="custom-cell">
            <v-btn
              v-if="checkPermission('GOTHMO')"
              small
              class="block-icon-reglement-fac mr-2"
              @click.prevent.stop="handleUpdate(item)"
              title="Modifier"
              :auto-focus="false"
            >
              <font-awesome-icon icon="pencil-alt" />
            </v-btn>
            <v-btn
              v-if="checkPermission('GOTHSO')"
              small
              class="block-icon-reglement-fac mr-2"
              :disabled="item.locked === 1"
              @click.prevent.stop="deleteOrganismeNew(item)"
              title="Supprimer"
              :auto-focus="false"
            >
              <font-awesome-icon icon="trash" />
            </v-btn>
            <v-btn
              v-if="checkPermission('GOTHDO')"
              small
              class="block-icon-reglement-fac mr-2"
              :disabled="item.locked === 1"
              @click.prevent.stop="DupplicateOrganismeNew(item)"
              title="Dupliquer"
              :auto-focus="false"
            >
              <font-awesome-icon icon="clone" />
            </v-btn>
          </td>
        </template>
      </v-data-table>
    </div>
    <div class="footer-style-table">
      <div class="text-center pagination-table">
        <v-pagination
          v-model="page"
          :length="totalPages"
          @input="pagination"
          :total-visible="7"
        ></v-pagination>
      </div>
      <div class="select-input-vuetify">
        <v-select
          v-model="perPage"
          :items="perPageList"
          @change="changePerPage"
          label="Eléments par page"
          outlined
          dense
          hide-details
          no-data-text="Aucun élément trouvé"
          :menu-props="{
            left: true,
            offsetX: true
          }"
          color="#704ad1"
          item-color="#704ad1"
        ></v-select>
      </div>
    </div>
    <!-- ADD ORGANISME -->
    <v-dialog
      v-model="addOrganismeNew"
      max-width="1500px"
      scrollable
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Ajouter un organisme</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="hideModal('addOrganismeNew')"
            title="Fermer"
            color="#704ad1"
            :auto-focus="false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card  mt-2">
          <v-form class="mt-2" ref="formAdd">
            <v-row>
              <v-col>
                <v-select
                  :items="computedListOrganisme"
                  v-model="organismeNewToAdd.organisme"
                  @change="
                    checkErrorsAdd();
                    tvaList(organismeNewToAdd.organisme);
                  "
                  item-text="name"
                  item-value="id"
                  label="Organisme"
                  :persistent-placeholder="true"
                  dense
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                ></v-select
              ></v-col>
              <v-col>
                <v-autocomplete
                  :items="computedListGroup"
                  v-model="organismeNewToAdd.group"
                  @changeValue="checkErrorsAdd()"
                  :persistent-placeholder="true"
                  item-text="name"
                  item-value="id"
                  :value="organismeNewToAdd.group"
                  label="Group"
                  dense
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                ></v-autocomplete>
              </v-col>
              <v-col>
                <v-menu :close-on-content-click="false" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="organismeNewToAdd.date_debut"
                      label="Date de début"
                      outlined
                      readonly
                      clearable
                      v-on="on"
                      v-bind="attrs"
                      :persistent-placeholder="true"
                      placeholder=""
                      prepend-inner-icon="mdi-calendar"
                      required
                      class="msg-error"
                      validate-on-blur
                      :rules="[v => !!v || 'Date de début est obligatoire']"
                      color="#704ad1"
                    ></v-text-field>
                  </template>

                  <v-date-picker
                    v-model="organismeNewToAdd.date_debut"
                    @input="checkErrorsAdd"
                    no-title
                    locale="fr"
                    :allowed-dates="disabledStartDate"
                    color="#704ad1"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col>
                <v-menu :close-on-content-click="false" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="organismeNewToAdd.date_fin"
                      label="Date de fin"
                      outlined
                      readonly
                      hide-details
                      clearable
                      v-on="on"
                      v-bind="attrs"
                      :persistent-placeholder="true"
                      placeholder=""
                      prepend-inner-icon="mdi-calendar"
                      color="#704ad1"
                    ></v-text-field>
                  </template>

                  <v-date-picker
                    v-model="organismeNewToAdd.date_fin"
                    @input="checkErrorsAdd"
                    no-title
                    locale="fr"
                    :allowed-dates="disabledEndDate"
                    color="#704ad1"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row
              :class="{ 'row-msg': errorDupplicate }"
              v-if="errorDupplicate"
            >
              <div class=" ml-1 margin-auto">
                <div class="messageError">
                  <div v-if="errorDupplicate" class="error-msg">
                    <ul v-if="Array.isArray(errorDupplicate)">
                      <li v-for="(e, index) in errorDupplicate" :key="index">
                        {{ e }}
                      </li>
                    </ul>
                    <div v-else>{{ errorDupplicate }}</div>
                  </div>
                </div>
              </div>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Label Coef prime"
                  :persistent-placeholder="true"
                  v-model="organismeNewToAdd.label_commercial_prime"
                  dense
                  outlined
                  required
                  :auto-focus="false"
                  :rules="[v => !!v || 'Label Coef prime est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Coef prime Formule"
                  :persistent-placeholder="true"
                  v-model="organismeNewToAdd.coef_commercial_prime"
                  dense
                  outlined
                  required
                  @keyup="checkFormuleConfigFraisDynamic('add')"
                  :auto-focus="false"
                  :rules="[v => !!v || 'Coef prime Formule est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  color="#704ad1"
                >
                </v-text-field>
                <em class="text-danger">{{ errorFormule }} </em></v-col
              >
              <v-col>
                <v-select
                  :items="computedGetNumeriqCulomn"
                  @input="CopyFields($event)"
                  :label="
                    isTextCopied ? 'Colonne :   Text copier ...' : 'Colonne : '
                  "
                  dense
                  outlined
                  :persistent-placeholder="true"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                ></v-select>
              </v-col>
              <v-col>
                <v-text-field
                  label="Formule finale"
                  :persistent-placeholder="true"
                  :value="
                    computedFormule(organismeNewToAdd.coef_commercial_prime)
                  "
                  dense
                  outlined
                  :auto-focus="false"
                  color="#704ad1"
                >
                </v-text-field
              ></v-col>
            </v-row>
            <v-row>
              <v-col cols="5">
                <v-select
                  :items="computedgetAllTvas"
                  class="select-menu"
                  label="Tva Coef prime"
                  :persistent-placeholder="true"
                  v-model="organismeNewToAdd.tva_coef_commercial_prime"
                  item-value="valeur"
                  item-text="valeur"
                  no-data-text=""
                  required
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                  dense
                  :auto-focus="false"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>
              <v-col cols="1" class="col-plus">
                <tva
                  :filiale_id="organismeNewToAdd.organisme"
                  @addTva="addTvaAction"
                  v-if="organismeNewToAdd.organisme"
                ></tva>
              </v-col>
              <v-col>
                <v-textarea
                  outlined
                  label="Description Coef prime"
                  v-model="organismeNewToAdd.description__commercial_prime"
                  :persistent-placeholder="true"
                  rows="1"
                  color="#704ad1"
                ></v-textarea
              ></v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Label complémentaire"
                  :persistent-placeholder="true"
                  v-model="organismeNewToAdd.label_missing_prime"
                  dense
                  outlined
                  required
                  :auto-focus="false"
                  :rules="[v => !!v || 'Label complémentaire est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-textarea
                  outlined
                  label="Description complémentaire"
                  v-model="organismeNewToAdd.description_missing_prime"
                  :persistent-placeholder="true"
                  rows="1"
                  color="#704ad1"
                ></v-textarea
              ></v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Label complémentaire avoir"
                  :persistent-placeholder="true"
                  v-model="organismeNewToAdd.label_missing_prime_av"
                  dense
                  outlined
                  required
                  :auto-focus="false"
                  :rules="[
                    v => !!v || 'Label complémentaire avoir est obligatoire'
                  ]"
                  class="msg-error"
                  validate-on-blur
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-textarea
                  outlined
                  label="Description complémentaire avoir"
                  v-model="organismeNewToAdd.description_missing_prime_av"
                  :persistent-placeholder="true"
                  rows="1"
                  color="#704ad1"
                ></v-textarea
              ></v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Coef Prime Installateur"
                  :persistent-placeholder="true"
                  v-model="organismeNewToAdd.coef_prime_installateur"
                  required
                  :rules="[
                    v => !!v || 'Coef Prime Installateur est obligatoire'
                  ]"
                  class="msg-error"
                  validate-on-blur
                  dense
                  type="number"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Tva Coef Prime Installateur"
                  :persistent-placeholder="true"
                  v-model="organismeNewToAdd.tva_coef_prime_installateur"
                  disabled
                  dense
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Label Coef commission commercial group"
                  :persistent-placeholder="true"
                  v-model="organismeNewToAdd.label_commercial_group"
                  required
                  :rules="[
                    v =>
                      !!v ||
                      'Label Coef commission commercial group est obligatoire'
                  ]"
                  class="msg-error"
                  validate-on-blur
                  dense
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Coef commission commercial group"
                  :persistent-placeholder="true"
                  v-model="organismeNewToAdd.coef_commercial_group"
                  required
                  :rules="[
                    v =>
                      !!v || 'Coef commission commercial groupest obligatoire'
                  ]"
                  class="msg-error"
                  validate-on-blur
                  dense
                  outlined
                  type="number"
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Tva coef commission commercial groupe"
                  :persistent-placeholder="true"
                  v-model="organismeNewToAdd.tva_coef_commercial_group"
                  required
                  :rules="[
                    v =>
                      !!v ||
                      'Tva coef commission commercial groupe est obligatoire'
                  ]"
                  class="msg-error"
                  validate-on-blur
                  dense
                  outlined
                  type="number"
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-textarea
                  outlined
                  label="Description coef commission commercial groupe"
                  :persistent-placeholder="true"
                  rows="3"
                  v-model="organismeNewToAdd.description_commercial_group"
                  color="#704ad1"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Label Coef surprime group"
                  :persistent-placeholder="true"
                  v-model="organismeNewToAdd.label_surprime_group"
                  required
                  :rules="[
                    v => !!v || 'Label Coef surprime group est obligatoire'
                  ]"
                  class="msg-error"
                  validate-on-blur
                  dense
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Coef surprime group"
                  :persistent-placeholder="true"
                  v-model="organismeNewToAdd.coef_surprime_group"
                  type="number"
                  required
                  :rules="[v => !!v || 'Coef surprime group est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  dense
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Tva coef surprime groupe"
                  :persistent-placeholder="true"
                  v-model="organismeNewToAdd.tva_coef_surprime_group"
                  required
                  :rules="[
                    v => !!v || 'Tva coef surprime groupe est obligatoire'
                  ]"
                  class="msg-error"
                  validate-on-blur
                  dense
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-textarea
                  outlined
                  label="Description coef surprime groupe"
                  :persistent-placeholder="true"
                  rows="3"
                  v-model="organismeNewToAdd.description_surprime_group"
                  color="#704ad1"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Label Commissions Gestion dossier et administrative"
                  :persistent-placeholder="true"
                  v-model="
                    organismeNewToAdd.label_commission_gestion_dossier_administrative
                  "
                  required
                  :rules="[
                    v =>
                      !!v ||
                      'Label Commissions Gestion dossier et administrative est obligatoire'
                  ]"
                  class="msg-error"
                  validate-on-blur
                  dense
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Coef Commissions Gestion dossier et administrative"
                  :persistent-placeholder="true"
                  v-model="
                    organismeNewToAdd.coef_commission_gestion_dossier_administrative
                  "
                  type="number"
                  required
                  :rules="[
                    v =>
                      !!v ||
                      'Coef Commissions Gestion dossier et administrative est obligatoire'
                  ]"
                  class="msg-error"
                  validate-on-blur
                  dense
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Tva Commissions Gestion dossier et administrative"
                  :persistent-placeholder="true"
                  v-model="
                    organismeNewToAdd.tva_coef_commission_gestion_dossier_administrative
                  "
                  required
                  :rules="[
                    v =>
                      !!v ||
                      'Tva Commissions Gestion dossier et administrative est obligatoire'
                  ]"
                  class="msg-error"
                  validate-on-blur
                  dense
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-textarea
                  outlined
                  label="Description Commissions Gestion dossier et administrative"
                  :persistent-placeholder="true"
                  rows="3"
                  v-model="
                    organismeNewToAdd.description_commission_gestion_dossier_administrative
                  "
                  color="#704ad1"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <div class="messageError">
          <div v-if="error" class="error-msg">
            <ul v-if="Array.isArray(error)">
              <li
                v-for="(e, index) in error"
                :key="index"
                style="list-style-type: none"
              >
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ error }}</div>
          </div>
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            outlined
            :disabled="!validateToAddconf"
            :loading="loading"
            :class="{ loader: loading }"
            @click.prevent.stop="hendleSubmitOrganismeNew"
            :auto-focus="false"
            >Valider
          </v-btn>
          <v-btn
            outlined
            @click.prevent="hideModal('addOrganismeNew')"
            :auto-focus="false"
          >
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END ADD ORGANISME -->
    <!-- DELTEED ORGANISME -->
    <v-dialog
      v-model="deleteOrganismeThNewModal"
      max-width="820px"
      scrollable
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Supprimer un organisme</v-label>

          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="hideModal('deleteOrganismeThNewModal')"
            title="Fermer"
            color="#704ad1"
            :auto-focus="false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <div class="mt-4 ml-3">
            <p>
              Êtes-vous sur de vouloir supprimer cette client
              <span class="bold-text">{{
                selectedOrganisme ? selectedOrganisme.organisme.name : '-'
              }}</span>
              ?
            </p>
          </div>
        </v-card-text>
        <div class="messageError">
          <div v-if="error" class="error-msg">
            <ul v-if="Array.isArray(error)">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ error }}</div>
          </div>
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            outlined
            :loading="getOrganismeThNewLoading"
            :class="{ loader: getOrganismeThNewLoading }"
            @click.prevent.stop="handleSuprimerOrganismeNew"
            :auto-focus="false"
            >Valider
          </v-btn>
          <v-btn
            outlined
            @click.prevent="hideModal('deleteOrganismeThNewModal')"
            :auto-focus="false"
          >
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END DELETED ORGANISME -->
    <!-- UPDATE ORGANISME -->
    <v-dialog
      v-model="updateOrganisme"
      max-width="1500px"
      scrollable
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Modifier un organisme</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="hideModal('updateOrganisme')"
            title="Fermer"
            color="#704ad1"
            :auto-focus="false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card  mt-2">
          <v-form class="mt-2" v-if="organismeNewToUpdate">
            <v-row class="content-btn-params-specifique">
              <v-btn
                small
                class="btn-add-setting mr-1"
                @click.prevent.stop="handleModalParametreSpec"
                v-if="checkPermission('GOTHAPSO')"
                :auto-focus="false"
              >
                <font-awesome-icon icon="plus" class="mr-3 icon" /> Paramètres
                Spécifique
              </v-btn>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  :items="computedListOrganisme"
                  v-model="organismeNewToUpdate.organisme.id"
                  @change="
                    checkErrorsAdd, tvaList(organismeNewToUpdate.organisme.id)
                  "
                  item-text="name"
                  item-value="id"
                  label="Organisme"
                  :auto-focus="false"
                  :persistent-placeholder="true"
                  dense
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                ></v-select
              ></v-col>
              <v-col>
                <v-autocomplete
                  :items="computedListGroup"
                  v-model="organismeNewToUpdate.group.id"
                  @changeValue="checkErrorsUpdate()"
                  :persistent-placeholder="true"
                  item-text="name"
                  item-value="id"
                  :value="organismeNewToUpdate.group.id"
                  label="Group"
                  dense
                  :auto-focus="false"
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                ></v-autocomplete>
              </v-col>
              <v-col>
                <v-menu :close-on-content-click="false" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="organismeNewToUpdate.date_debut"
                      label="Date de début"
                      outlined
                      readonly
                      clearable
                      :auto-focus="false"
                      v-on="on"
                      v-bind="attrs"
                      :persistent-placeholder="true"
                      placeholder=""
                      required
                      class="msg-error"
                      validate-on-blur
                      :rules="[v => !!v || 'Date de début est obligatoire']"
                      prepend-inner-icon="mdi-calendar"
                      color="#704ad1"
                    ></v-text-field>
                  </template>

                  <v-date-picker
                    v-model="organismeNewToUpdate.date_debut"
                    @input="checkErrorsUpdate"
                    no-title
                    :auto-focus="false"
                    locale="fr"
                    :allowed-dates="disabledStartDateUpdate"
                    color="#704ad1"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col>
                <v-menu :close-on-content-click="false" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="organismeNewToUpdate.date_fin"
                      label="Date de fin"
                      outlined
                      readonly
                      hide-details
                      clearable
                      v-on="on"
                      v-bind="attrs"
                      :auto-focus="false"
                      :persistent-placeholder="true"
                      placeholder=""
                      prepend-inner-icon="mdi-calendar"
                      color="#704ad1"
                    ></v-text-field>
                  </template>

                  <v-date-picker
                    v-model="organismeNewToUpdate.date_fin"
                    @input="checkErrorsUpdate"
                    no-title
                    locale="fr"
                    :auto-focus="false"
                    :allowed-dates="disabledEndDateUpdate"
                    color="#704ad1"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row
              :class="{ 'row-msg': errorDupplicate }"
              v-if="errorDupplicate"
            >
              <div class=" ml-1 margin-auto">
                <div class="messageError">
                  <div v-if="errorDupplicate" class="error-msg">
                    <ul v-if="Array.isArray(errorDupplicate)">
                      <li v-for="(e, index) in errorDupplicate" :key="index">
                        {{ e }}
                      </li>
                    </ul>
                    <div v-else>{{ errorDupplicate }}</div>
                  </div>
                </div>
              </div>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Label Coef prime"
                  :persistent-placeholder="true"
                  v-model="organismeNewToUpdate.label_commercial_prime"
                  dense
                  outlined
                  required
                  :auto-focus="false"
                  :rules="[v => !!v || 'Label Coef prime est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Coef prime Formule"
                  :persistent-placeholder="true"
                  v-model="organismeNewToUpdate.coef_commercial_prime"
                  dense
                  outlined
                  required
                  @keyup="checkFormuleConfigFraisDynamic('update')"
                  :auto-focus="false"
                  :rules="[v => !!v || 'Coef prime Formule est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  color="#704ad1"
                >
                </v-text-field>
                <em class="text-danger">{{ errorFormule }} </em></v-col
              >
              <v-col>
                <v-select
                  :items="computedGetNumeriqCulomn"
                  @input="CopyFields($event)"
                  :label="
                    isTextCopied ? 'Colonne :   Text copier ...' : 'Colonne : '
                  "
                  dense
                  outlined
                  :persistent-placeholder="true"
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                ></v-select>
              </v-col>
              <v-col>
                <v-text-field
                  label="Formule finale"
                  :persistent-placeholder="true"
                  :value="
                    computedFormule(organismeNewToUpdate.coef_commercial_prime)
                  "
                  dense
                  outlined
                  :auto-focus="false"
                  color="#704ad1"
                >
                </v-text-field
              ></v-col>
            </v-row>
            <v-row>
              <v-col cols="5">
                <v-select
                  :items="computedgetAllTvas"
                  class="select-menu"
                  label="Tva Coef prime"
                  :persistent-placeholder="true"
                  v-model="organismeNewToUpdate.tva_coef_commercial_prime"
                  item-value="valeur"
                  item-text="valeur"
                  no-data-text=""
                  required
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                  dense
                  :auto-focus="false"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>
              <v-col cols="1" class="col-plus">
                <tva
                  :filiale_id="organismeNewToUpdate.organisme.id"
                  @addTva="addTvaAction"
                  v-if="organismeNewToUpdate.organisme.id"
                ></tva>
              </v-col>

              <v-col>
                <v-textarea
                  outlined
                  label="Description Coef prime"
                  v-model="organismeNewToUpdate.description__commercial_prime"
                  :persistent-placeholder="true"
                  :auto-focus="false"
                  rows="1"
                  color="#704ad1"
                ></v-textarea
              ></v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Label complémentaire"
                  :persistent-placeholder="true"
                  v-model="organismeNewToUpdate.label_missing_prime"
                  dense
                  outlined
                  required
                  :auto-focus="false"
                  :rules="[v => !!v || 'Label complémentaire est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-textarea
                  outlined
                  label="Description complémentaire"
                  v-model="organismeNewToUpdate.description_missing_prime"
                  :persistent-placeholder="true"
                  :auto-focus="false"
                  rows="1"
                  color="#704ad1"
                ></v-textarea
              ></v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Label complémentaire avoir"
                  :persistent-placeholder="true"
                  v-model="organismeNewToUpdate.label_missing_prime_av"
                  dense
                  outlined
                  required
                  :auto-focus="false"
                  :rules="[
                    v => !!v || 'Label complémentaire avoir est obligatoire'
                  ]"
                  class="msg-error"
                  validate-on-blur
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-textarea
                  outlined
                  label="Description complémentaire avoir"
                  v-model="organismeNewToUpdate.description_missing_prime_av"
                  :persistent-placeholder="true"
                  :auto-focus="false"
                  rows="1"
                  color="#704ad1"
                ></v-textarea
              ></v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Coef Prime Installateur"
                  :persistent-placeholder="true"
                  :auto-focus="false"
                  v-model="organismeNewToUpdate.coef_prime_installateur"
                  required
                  :rules="[
                    v => !!v || 'Coef Prime Installateur est obligatoire'
                  ]"
                  class="msg-error"
                  validate-on-blur
                  dense
                  type="number"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Tva Coef Prime Installateur"
                  :persistent-placeholder="true"
                  :auto-focus="false"
                  v-model="organismeNewToUpdate.tva_coef_prime_installateur"
                  disabled
                  dense
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Label Coef commission commercial group"
                  :persistent-placeholder="true"
                  :auto-focus="false"
                  v-model="organismeNewToUpdate.label_commercial_group"
                  required
                  :rules="[
                    v =>
                      !!v ||
                      'Label Coef commission commercial group est obligatoire'
                  ]"
                  class="msg-error"
                  validate-on-blur
                  dense
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Coef commission commercial group"
                  :persistent-placeholder="true"
                  :auto-focus="false"
                  v-model="organismeNewToUpdate.coef_commercial_group"
                  required
                  :rules="[
                    v =>
                      !!v || 'Coef commission commercial groupest obligatoire'
                  ]"
                  class="msg-error"
                  validate-on-blur
                  dense
                  outlined
                  type="number"
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Tva coef commission commercial groupe"
                  :persistent-placeholder="true"
                  :auto-focus="false"
                  v-model="organismeNewToUpdate.tva_coef_commercial_group"
                  required
                  :rules="[
                    v =>
                      !!v ||
                      'Tva coef commission commercial groupe est obligatoire'
                  ]"
                  class="msg-error"
                  validate-on-blur
                  dense
                  outlined
                  type="number"
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-textarea
                  outlined
                  label="Description coef commission commercial groupe"
                  :persistent-placeholder="true"
                  :auto-focus="false"
                  rows="3"
                  v-model="organismeNewToUpdate.description_commercial_group"
                  color="#704ad1"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Label Coef surprime group"
                  :persistent-placeholder="true"
                  :auto-focus="false"
                  v-model="organismeNewToUpdate.label_surprime_group"
                  required
                  :rules="[
                    v => !!v || 'Label Coef surprime group est obligatoire'
                  ]"
                  class="msg-error"
                  validate-on-blur
                  dense
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Coef surprime group"
                  :persistent-placeholder="true"
                  :auto-focus="false"
                  v-model="organismeNewToUpdate.coef_surprime_group"
                  type="number"
                  required
                  :rules="[v => !!v || 'Coef surprime group est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  dense
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Tva coef surprime groupe"
                  :persistent-placeholder="true"
                  :auto-focus="false"
                  v-model="organismeNewToUpdate.tva_coef_surprime_group"
                  required
                  :rules="[
                    v => !!v || 'Tva coef surprime groupe est obligatoire'
                  ]"
                  class="msg-error"
                  validate-on-blur
                  dense
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-textarea
                  outlined
                  :auto-focus="false"
                  label="Description coef surprime groupe"
                  :persistent-placeholder="true"
                  rows="3"
                  v-model="organismeNewToUpdate.description_surprime_group"
                  color="#704ad1"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Label Commissions Gestion dossier et administrative"
                  :persistent-placeholder="true"
                  :auto-focus="false"
                  v-model="
                    organismeNewToUpdate.label_commission_gestion_dossier_administrative
                  "
                  required
                  :rules="[
                    v =>
                      !!v ||
                      'Label Commissions Gestion dossier et administrative est obligatoire'
                  ]"
                  class="msg-error"
                  validate-on-blur
                  dense
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Coef Commissions Gestion dossier et administrative"
                  :persistent-placeholder="true"
                  :auto-focus="false"
                  v-model="
                    organismeNewToUpdate.coef_commission_gestion_dossier_administrative
                  "
                  type="number"
                  required
                  :rules="[
                    v =>
                      !!v ||
                      'Coef Commissions Gestion dossier et administrative est obligatoire'
                  ]"
                  class="msg-error"
                  validate-on-blur
                  dense
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Tva Commissions Gestion dossier et administrative"
                  :persistent-placeholder="true"
                  v-model="
                    organismeNewToUpdate.tva_coef_commission_gestion_dossier_administrative
                  "
                  required
                  :rules="[
                    v =>
                      !!v ||
                      'Tva Commissions Gestion dossier et administrative est obligatoire'
                  ]"
                  class="msg-error"
                  validate-on-blur
                  dense
                  outlined
                  color="#704ad1"
                  :auto-focus="false"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-textarea
                  :auto-focus="false"
                  outlined
                  label="Description Commissions Gestion dossier et administrative"
                  :persistent-placeholder="true"
                  rows="3"
                  v-model="
                    organismeNewToUpdate.description_commission_gestion_dossier_administrative
                  "
                  color="#704ad1"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <div class="messageError">
          <div v-if="error" class="error-msg">
            <ul v-if="Array.isArray(error)">
              <li
                v-for="(e, index) in error"
                :key="index"
                style="list-style-type: none"
              >
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ error }}</div>
          </div>
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            outlined
            :disabled="!validateToAddconf"
            :loading="loading"
            :class="{ loader: loading }"
            @click.prevent.stop="handleSubmitUpdateOrganismeNew"
            :auto-focus="false"
            >Valider
          </v-btn>
          <v-btn
            outlined
            @click.prevent="hideModal('updateOrganisme')"
            :auto-focus="false"
          >
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END UPDATE ORGANISME -->
    <!--  -->
    <v-dialog
      v-model="modalParametreSpec"
      max-width="1200px"
      scrollable
      hide-overlay
      persistent
      content-class="custom-vuetify-dialog-gestion-organisme"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Paramètres Spécifiques</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="hideModal('modalParametreSpec')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>
        <v-card-text class="body-card  mt-2">
          <div
            v-if="parametreFixeOrganismeLoading"
            class="message text-aligne-center"
          >
            <div
              class="loading-custom-template-block style-half-circle-loder-organisme"
            >
              <Half-circle-spinner
                :animation-duration="1000"
                :size="50"
                :color="'#704ad1'"
                class="loeder"
              />
            </div>
          </div>
          <v-form class="mt-2" v-else :auto-focus="false">
            <v-row class="content-btn-params-specifique" :auto-focus="false">
              <v-btn
                small
                class="btn-add-setting mr-1 mb-2"
                @click="addLineParametreSpec()"
                v-if="checkPermission('GOTHAPSO')"
                :auto-focus="false"
              >
                <font-awesome-icon icon="plus" class="mr-3 icon" /> Ajouter un
                paramètre spécifique
              </v-btn>
            </v-row>
            <v-row
              class="mb-1 parametre_block pt-3"
              v-for="(parametre, index) in parametreSpec"
              :key="'index' + index"
            >
              <v-row>
                <v-col col xs="10" sm="10" md="10">
                  <v-autocomplete
                    :items="getFiliaeOfResponsable"
                    v-model="parametre.filiale_id.id"
                    :persistent-placeholder="true"
                    @change="
                      setTvaListSpecific(
                        parametre.filiale_id.id,
                        'parametreSpec',
                        index
                      )
                    "
                    item-text="name"
                    item-value="id"
                    label="Société"
                    dense
                    :auto-focus="false"
                    outlined
                    :menu-props="{ bottom: true, offsetY: true }"
                    color="#704ad1"
                    item-color="#704ad1"
                  ></v-autocomplete>
                </v-col>
                <v-col col xs="2" sm="2" md="2">
                  <div class="edit-action">
                    <v-btn
                      @click.prevent.stop="
                        handleSubmitParametre(parametre, index)
                      "
                      v-if="checkPermission('GOTHAPSO')"
                      small
                      class="block-icon-reglement-fac mr-2"
                      title="Ajouter"
                      :auto-focus="false"
                    >
                      <font-awesome-icon icon="check" />
                    </v-btn>

                    <v-btn
                      @click.prevent.stop="deleteBlockParametre(index)"
                      small
                      class="block-icon-reglement-fac mr-2"
                      title="supprimer"
                      :auto-focus="false"
                    >
                      <font-awesome-icon icon="trash" />
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col col xs="3" sm="3" md="3">
                  <v-text-field
                    label="Coefficient prime Installateur"
                    :persistent-placeholder="true"
                    v-model="parametre.coef_prime_installateur"
                    type="number"
                    min="0"
                    step="0.01"
                    dense
                    outlined
                    required
                    :auto-focus="false"
                    :rules="[
                      v =>
                        !!v || 'Coefficient prime Installateur est obligatoire'
                    ]"
                    class="msg-error"
                    validate-on-blur
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col col xs="3" sm="3" md="3">
                  <v-text-field
                    label="TVA Coefficient prime Installateur"
                    :persistent-placeholder="true"
                    v-model="parametre.tva_coef_prime_installateur"
                    type="number"
                    dense
                    outlined
                    required
                    :auto-focus="false"
                    :rules="[
                      v =>
                        !!v ||
                        'TVA Coefficient prime Installateur est obligatoire'
                    ]"
                    class="msg-error"
                    validate-on-blur
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col col xs="3" sm="3" md="3">
                  <v-text-field
                    label="Coefficient Commercial Installateur"
                    :persistent-placeholder="true"
                    v-model="parametre.coef_commercial_prime"
                    dense
                    outlined
                    required
                    :auto-focus="false"
                    :rules="[
                      v =>
                        !!v ||
                        'TVA Coefficient prime Installateur est obligatoire'
                    ]"
                    class="msg-error"
                    validate-on-blur
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <!-- <v-col col xs="3" sm="3" md="3"> -->
                <v-col cols="2">
                  <v-select
                    :items="computedgetAllTvasSpe(parametre.listTva)"
                    class="select-menu"
                    label="TVA Coefficient Commercial Installateur"
                    :persistent-placeholder="true"
                    v-model="parametre.tva_coef_commercial_prime"
                    item-value="valeur"
                    item-text="valeur"
                    no-data-text=""
                    required
                    outlined
                    :menu-props="{ bottom: true, offsetY: true }"
                    dense
                    :auto-focus="false"
                    color="#704ad1"
                    item-color="#704ad1"
                  >
                  </v-select>
                </v-col>
                <v-col cols="1" class="col-plus">
                  <tva
                    :filiale_id="parametre.filiale_id.id"
                    @addTva="addTvaActionSpe($event, 'parametreSpec', index)"
                    v-if="parametre.filiale_id.id"
                  ></tva>
                </v-col>
                <!-- <v-text-field
                    label="TVA Coefficient Commercial Installateur"
                    :persistent-placeholder="true"
                    v-model="parametre.tva_coef_commercial_prime"
                    type="number"
                    min="0"
                    step="0.01"
                    dense
                    outlined
                    required
                    :auto-focus="false"
                    :rules="[
                      v =>
                        !!v ||
                        'TVA Coefficient Commercial Installateur est obligatoire'
                    ]"
                    class="msg-error"
                    validate-on-blur
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col> -->
                <v-col col xs="3" sm="3" md="3">
                  <v-text-field
                    label="Coefficient Commercial groupe"
                    :persistent-placeholder="true"
                    v-model="parametre.coef_commercial_group"
                    type="number"
                    min="0"
                    step="0.01"
                    dense
                    outlined
                    required
                    :auto-focus="false"
                    :rules="[
                      v =>
                        !!v || 'Coefficient Commercial groupe est obligatoire'
                    ]"
                    class="msg-error"
                    validate-on-blur
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col col xs="3" sm="3" md="3">
                  <v-text-field
                    label="TVA Coefficient Commercial groupe"
                    :persistent-placeholder="true"
                    v-model="parametre.tva_coef_commercial_group"
                    type="number"
                    min="0"
                    step="0.01"
                    dense
                    outlined
                    required
                    :auto-focus="false"
                    :rules="[
                      v =>
                        !!v ||
                        'TVA Coefficient Commercial groupe est obligatoire'
                    ]"
                    class="msg-error"
                    validate-on-blur
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col col xs="3" sm="3" md="3">
                  <v-text-field
                    label="Coefficient surprime groupe"
                    :persistent-placeholder="true"
                    v-model="parametre.coef_surprime_group"
                    type="number"
                    min="0"
                    step="0.01"
                    dense
                    outlined
                    required
                    :auto-focus="false"
                    :rules="[
                      v => !!v || 'Coefficient surprime groupe est obligatoire'
                    ]"
                    class="msg-error"
                    validate-on-blur
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col col xs="3" sm="3" md="3">
                  <v-text-field
                    label="TVA Coefficient surprime groupe"
                    :persistent-placeholder="true"
                    v-model="parametre.tva_coef_surprime_group"
                    type="number"
                    min="0"
                    step="0.01"
                    dense
                    outlined
                    required
                    :auto-focus="false"
                    :rules="[
                      v =>
                        !!v || 'TVA Coefficient surprime groupe est obligatoire'
                    ]"
                    class="msg-error"
                    validate-on-blur
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col col xs="6" sm="6" md="6">
                  <v-text-field
                    label="Coefficient Commissions Gestion dossier et administrative "
                    :persistent-placeholder="true"
                    v-model="
                      parametre.coef_commission_gestion_dossier_administrative
                    "
                    type="number"
                    min="0"
                    step="0.01"
                    dense
                    outlined
                    required
                    :auto-focus="false"
                    :rules="[
                      v =>
                        !!v ||
                        'Coefficient Commissions Gestion dossier et administrative est obligatoire'
                    ]"
                    class="msg-error"
                    validate-on-blur
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col col xs="6" sm="6" md="6">
                  <v-text-field
                    label="TVA Coefficient Commissions Gestion dossier et administrative"
                    :persistent-placeholder="true"
                    v-model="
                      parametre.tva_coef_commission_gestion_dossier_administrative
                    "
                    type="number"
                    min="0"
                    step="0.01"
                    dense
                    outlined
                    required
                    :auto-focus="false"
                    :rules="[
                      v =>
                        !!v ||
                        'TVA Coefficient Commissions Gestion dossier et administrative est obligatoire'
                    ]"
                    class="msg-error"
                    validate-on-blur
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <div>
                  <div class="messageError">
                    <div v-if="parametreFixeOrganismeError" class="error-msg">
                      <ul v-if="Array.isArray(parametreFixeOrganismeError)">
                        <li
                          class="color-message"
                          style="list-style: none; color: red"
                          v-for="(e, index) in parametreFixeOrganismeError"
                          :key="index"
                        >
                          {{ e }}
                        </li>
                      </ul>
                      <div v-else class="color-message">
                        {{ parametreFixeOrganismeError }}
                      </div>
                    </div>
                  </div>
                </div>
              </v-row>
            </v-row>
            <v-row
              class="mb-1 parametre_block pt-3 pl-2 pr-2 mb-3"
              v-for="(parametre, index) in parametreFixeOrganisme"
              :key="index"
            >
              <v-row>
                <v-col col xs="10" sm="10" md="10">
                  <v-autocomplete
                    :items="getFiliaeOfResponsable"
                    v-model="parametre.filiale_id.id"
                    :persistent-placeholder="true"
                    @change="
                      setTvaListSpecific(
                        parametre.filiale_id.id,
                        'parametreFixeOrganisme',
                        index
                      )
                    "
                    :disabled="true"
                    item-text="name"
                    item-value="id"
                    label="Société"
                    dense
                    :auto-focus="false"
                    outlined
                    :menu-props="{ bottom: true, offsetY: true }"
                    color="#704ad1"
                    item-color="#704ad1"
                  ></v-autocomplete>
                </v-col>
                <v-col col xs="2" sm="2" md="2">
                  <div class="edit-action">
                    <v-btn
                      @click.prevent.stop="
                        updateParametreSpec(parametre, 'parametre' + index)
                      "
                      v-if="
                        checkPermission('GOTHMPSO') &&
                          indexUpdate == 'parametre' + index
                      "
                      small
                      title="Valider la modification"
                      class="block-icon-reglement-fac mr-2"
                      :auto-focus="false"
                    >
                      <font-awesome-icon icon="check" />
                    </v-btn>
                    <v-btn
                      small
                      class="block-icon-reglement-fac mr-2"
                      @click.prevent.stop="changeDisabled('parametre' + index)"
                      title="Modifier"
                      v-if="showModify == false && checkPermission('GOTHMPSO')"
                      :auto-focus="false"
                    >
                      <font-awesome-icon icon="pencil-alt" />
                    </v-btn>
                    <v-btn
                      v-if="checkPermission('GOTHMSPSO')"
                      title="supprimer"
                      @click.prevent.stop="handleModalDelete(parametre)"
                      small
                      class="block-icon-reglement-fac mr-2"
                      :auto-focus="false"
                    >
                      <font-awesome-icon icon="trash" />
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col col xs="3" sm="3" md="3">
                  <v-text-field
                    :class="'parametre' + index"
                    label="Coefficient prime Installateur"
                    :persistent-placeholder="true"
                    v-model="parametre.coef_prime_installateur"
                    type="number"
                    :disabled="indexUpdate != 'parametre' + index"
                    dense
                    outlined
                    :auto-focus="false"
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col col xs="3" sm="3" md="3">
                  <v-text-field
                    label="TVA Coefficient  prime Installateur"
                    :disabled="indexUpdate != 'parametre' + index"
                    :persistent-placeholder="true"
                    :class="'parametre' + index"
                    v-model="parametre.tva_coef_prime_installateur"
                    type="number"
                    dense
                    outlined
                    :auto-focus="false"
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col col xs="3" sm="3" md="3">
                  <v-text-field
                    label="Coefficient Commercial Installateur"
                    :disabled="indexUpdate != 'parametre' + index"
                    :persistent-placeholder="true"
                    :class="'parametre' + index"
                    v-model="parametre.coef_commercial_prime"
                    dense
                    outlined
                    :auto-focus="false"
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <!-- <v-col col xs="3" sm="3" md="3"> -->
                <v-col cols="2">
                  <v-select
                    :items="[...computedgetAllTvasSpe(parametre.listTva)]"
                    class="select-menu"
                    label="Tva Coef prime"
                    :persistent-placeholder="true"
                    v-model="parametre.tva_coef_commercial_prime"
                    item-value="valeur"
                    item-text="valeur"
                    no-data-text=""
                    required
                    outlined
                    :menu-props="{ bottom: true, offsetY: true }"
                    dense
                    :auto-focus="false"
                    color="#704ad1"
                    item-color="#704ad1"
                    :disabled="indexUpdate != 'parametre' + index"
                  >
                  </v-select>
                </v-col>
                <v-col cols="1" class="col-plus">
                  <tva
                    :filiale_id="parametre.filiale_id.id"
                    @addTva="
                      addTvaActionSpe($event, 'parametreFixeOrganisme', index)
                    "
                    v-if="parametre.filiale_id.id"
                  ></tva>
                </v-col>
                <v-col col xs="3" sm="3" md="3">
                  <v-text-field
                    label="Coefficient Commercial groupe"
                    :disabled="indexUpdate != 'parametre' + index"
                    :persistent-placeholder="true"
                    :class="'parametre' + index"
                    type="number"
                    v-model="parametre.coef_commercial_group"
                    dense
                    outlined
                    :auto-focus="false"
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col col xs="3" sm="3" md="3">
                  <v-text-field
                    label="TVA Coefficient Commercial groupe"
                    :disabled="indexUpdate != 'parametre' + index"
                    :persistent-placeholder="true"
                    :class="'parametre' + index"
                    type="number"
                    v-model="parametre.tva_coef_commercial_group"
                    dense
                    outlined
                    :auto-focus="false"
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col col xs="3" sm="3" md="3">
                  <v-text-field
                    label="Coefficient surprime groupe"
                    :disabled="indexUpdate != 'parametre' + index"
                    :persistent-placeholder="true"
                    :class="'parametre' + index"
                    type="number"
                    v-model="parametre.coef_surprime_group"
                    dense
                    outlined
                    :auto-focus="false"
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col col xs="3" sm="3" md="3">
                  <v-text-field
                    label="TVA Coefficient surprime groupe"
                    :disabled="indexUpdate != 'parametre' + index"
                    :persistent-placeholder="true"
                    :class="'parametre' + index"
                    type="number"
                    v-model="parametre.tva_coef_surprime_group"
                    dense
                    outlined
                    :auto-focus="false"
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col col xs="6" sm="6" md="6">
                  <v-text-field
                    label="Coefficient Commissions Gestion dossier et administrative "
                    :disabled="indexUpdate != 'parametre' + index"
                    :persistent-placeholder="true"
                    :class="'parametre' + index"
                    type="number"
                    v-model="
                      parametre.coef_commission_gestion_dossier_administrative
                    "
                    dense
                    outlined
                    :auto-focus="false"
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col col xs="6" sm="6" md="6">
                  <v-text-field
                    label="TVA Coefficient Commissions Gestion dossier et administrative"
                    :disabled="indexUpdate != 'parametre' + index"
                    :persistent-placeholder="true"
                    :class="'parametre' + index"
                    type="number"
                    v-model="
                      parametre.tva_coef_commission_gestion_dossier_administrative
                    "
                    dense
                    outlined
                    :auto-focus="false"
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-row>
          </v-form>
        </v-card-text>
        <div>
          <div class="messageError">
            <div v-if="parametreFixeOrganismeError" class="error-msg">
              <ul v-if="Array.isArray(parametreFixeOrganismeError)">
                <li
                  class="color-message"
                  style="list-style: none; color: red"
                  v-for="(e, index) in parametreFixeOrganismeError"
                  :key="index"
                >
                  {{ e }}
                </li>
              </ul>
              <div v-else class="color-message">
                {{ parametreFixeOrganismeError }}
              </div>
            </div>
          </div>
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>

          <v-btn
            outlined
            @click.prevent.stop="hideModal('modalParametreSpec')"
            :auto-focus="false"
          >
            Fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--  -->
    <!--  -->
    <v-dialog
      v-model="deleteParametreSpec"
      max-width="820px"
      scrollable
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header"
            >Supprimer un paramètre spécifique</v-label
          >

          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="hideModal('deleteParametreSpec')"
            title="Fermer"
            color="#704ad1"
            :auto-focus="false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <div class="mt-4 ml-3">
            <p>
              Êtes-vous sur de vouloir supprimer ce paramètre spécifique ?
            </p>
          </div>
        </v-card-text>
        <div class="messageError">
          <div v-if="error" class="error-msg">
            <ul v-if="Array.isArray(error)">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ error }}</div>
          </div>
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            outlined
            :loading="loadingDeleteParametre"
            :class="{ loader: loadingDeleteParametre }"
            @click.prevent.stop="handleDeleteParametreSpec"
            :auto-focus="false"
            >Valider
          </v-btn>
          <v-btn
            outlined
            @click.prevent="hideModal('deleteParametreSpec')"
            :auto-focus="false"
          >
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--  -->
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
export default {
  name: 'Gestion-des-organismes',
  data() {
    return {
      selectedTable: [],
      loadingDeleteParametre: false,
      parametreSpecToDelete: null,
      parametreToUpdate: null,
      showModify: false,
      indexUpdate: null,
      showUpdate: true,
      parametreSpec: [],
      errorFormule: null,
      isTextCopied: false,
      indexParametre: null,
      organismeNewToAdd: {
        organisme: null,
        group: null,
        coef_prime_installateur: null,
        tva_coef_prime_installateur: 0,
        coef_commercial_prime: null,
        tva_coef_commercial_prime: null,
        coef_commercial_group: null,
        tva_coef_commercial_group: 20,
        coef_surprime_group: null,
        tva_coef_surprime_group: 20,
        date_debut: null,
        date_fin: null,
        label_commercial_prime: null,
        description__commercial_prime: null,
        label_commercial_group: null,
        description_commercial_group: null,
        label_surprime_group: null,
        description_surprime_group: null,
        label_missing_prime: null,
        description_missing_prime: null,
        label_missing_prime_av: null,
        description_missing_prime_av: null,
        coef_commission_gestion_dossier_administrative: null,
        tva_coef_commission_gestion_dossier_administrative: 20,
        label_commission_gestion_dossier_administrative: null,
        description_commission_gestion_dossier_administrative: null,
        locked: 0
      },
      checkAll: false,
      dateStartForSearch: null,
      dateEndForSearch: null,
      error: null,
      loading: false,
      selectedOrganisme: null,
      perPage: 10,
      page: 1,
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 30, text: 30 }
      ],
      fields: [
        {
          value: 'check_all',
          text: '',
          width: '40px !important',
          tdClass: 'color'
        },
        {
          value: 'lock_icon',
          text: '',
          width: '40px !important',
          thClass: 'th-details-column-frais'
        },
        { value: 'organisme', text: 'Organisme' },
        { value: 'group', text: 'Group' },
        { value: 'date_debut', text: 'Date début' },
        { value: 'date_fin', text: 'Date fin' },
        { value: 'coef_prime_installateur', text: 'Coef Prime Installateur' },
        { value: 'coef_commercial_prime', text: 'Coef commercial prime' },
        { value: 'coef_commercial_group', text: 'Coef commercial group' },
        { value: 'coef_surprime_group', text: 'Coef surprime group' },
        {
          value: 'coef_commission_gestion_dossier_administrative',
          text: 'Coef Commissions Gestion dossier et administrative'
        },
        { value: 'Actions', text: 'Actions' }
      ],
      objToSend: {
        organisme_id: null,
        group_id: null,
        date_debut: null,
        date_fin: null,
        id: null
      },
      // organismeNewToUpdate: {
      //   organisme: null,
      //   group: null,
      //   coef_prime_installateur: null,
      //   tva_coef_prime_installateur: 0,
      //   coef_commercial_prime: 0,
      //   tva_coef_commercial_prime: 20,
      //   coef_commercial_group: null,
      //   tva_coef_commercial_group: 20,
      //   coef_surprime_group: null,
      //   tva_coef_surprime_group: 20,
      //   date_debut: null,
      //   date_fin: null,
      //   label_commercial_prime: null,
      //   description__commercial_prime: null,
      //   label_commercial_group: null,
      //   description_commercial_group: null,
      //   label_surprime_group: null,
      //   description_surprime_group: null,
      //   label_missing_prime: null,
      //   description_missing_prime: null,
      //   label_missing_prime_av: null,
      //   description_missing_prime_av: null
      // },
      organismeNewToUpdate: null,
      filterOrganisme: null,
      filterGroup: null,
      filterDate_debut: null,
      filterDate_fin: null,
      ListOrganisme: [],
      ListGroup: [],
      validateToAddconf: false,
      errorDupplicate: null,
      addOrganismeNew: false,
      deleteOrganismeThNewModal: false,
      updateOrganisme: false,
      modalParametreSpec: false,
      deleteParametreSpec: false,
      getAllTvas: []
    };
  },
  computed: {
    ...mapGetters([
      'getFiliaeOfResponsable',
      'getOrganismeThNew',
      'getOrganismeThNewLoading',
      'getOrganismeThNewError',
      'getSettingFilialesTh',
      'getTotalRowOrganismeThNew',
      'getOrganismeThNewErrorDupplication',
      'checkPermission',
      'getNumeriqCulomn',
      'parametreFixeOrganismeError',
      'parametreFixeOrganisme',
      'parametreFixeOrganismeLoading',
      'formateDateGetters',
      'getProFilials'
    ]),
    computedgetAllTvas() {
      return this.getAllTvas.filter(i => i.is_active == 1);
    },
    computedgetAllTvasSpe() {
      return function(data) {
        let test = data
          .filter(i => i.is_active == 1)
          .map(t => {
            return { valeur: t.valeur + '' };
          });
        return test;
      };
    },
    computedGetNumeriqCulomn: function() {
      return Object.entries(this.getNumeriqCulomn).map(item => {
        return item[1];
      });
    },
    totalPages() {
      if (this.getTotalRowOrganismeThNew) {
        return Math.ceil(this.getTotalRowOrganismeThNew / this.perPage);
      }
      return this.getTotalRowOrganismeThNew;
    },
    sizeBlockTable() {
      let heigthBlock = 25;
      let hei = 239 + heigthBlock;
      return (
        'max-height : calc(100vh - ' +
        `${hei}` +
        'px)!important ; height: calc(100vh - ' +
        `${hei}` +
        'px)!important;'
      );
    },
    sizeTable() {
      let heigthBlock = 25;
      let hei = 270 + heigthBlock;
      return (
        'max-height : calc(100vh - ' +
        `${hei}` +
        'px)!important ; height: calc(100vh - ' +
        `${hei}` +
        'px)!important;'
      );
    },

    computedFilterGroup() {
      let name = '';
      this.computedListGroup.map(item => {
        if (item.id == this.filterGroup) {
          name = item.name;
        }
      });
      return name;
    },
    computedFilterOrganisme() {
      let name = '';
      this.computedListOrganisme.map(item => {
        if (item.id == this.filterOrganisme) {
          name = item.name;
        }
      });
      return name;
    },
    computedFormule() {
      return function(data) {
        if (data) {
          let formule = data;
          Object.keys(this.getNumeriqCulomn).forEach(key => {
            formule = formule.replaceAll('$' + key, this.getNumeriqCulomn[key]);
          });

          return formule;
        }
        return '';
      };
    },
    computedCheckedRows() {
      let checkedRows = this.getOrganismeThNew.filter(
        item => item.check === true
      );
      return checkedRows;
    },
    computedListOrganisme() {
      let tab = this.getSettingFilialesTh.filter(
        element => element.type === 'organisme'
      );

      tab = tab.map(item => ({
        id: item.id,
        name: item.name,
        tva: item.tva
      }));
      return tab;
    },
    computedListGroup() {
      const tab = this.getSettingFilialesTh
        .filter(i => i.type != 'particulier')
        .map(item => ({
          id: item.id,
          name: item.name
        }));
      return tab;
    }
  },
  methods: {
    ...mapActions([
      'fetchAllParametreSpecifique',
      'fetchAllOrganismeThNew',
      'addNewOrganismeThNew',
      'EditOrganismeTh',
      'deletOrganismeThNew',
      'getSettingFilialeTh',
      'checkPeriodOrganism',
      'lock_unlock',
      'checkFormuleConfigFraisDynamicMethod',
      'getAllNumColums',
      'getFilialsOfConnectedResponsable',
      'addNewParametreSpecfique',
      'deleteParametreSpecifique',
      'updateParametreSpecifique',
      'getAllProFilial'
    ]),
    addTvaAction(data) {
      this.getAllTvas.push(data);
    },
    setTvaListSpecific(id, params, index, reset = true) {
      if (reset) {
        this[params][index].tva_coef_commercial_prime = null;
      }
      this[params][index].listTva = this.tvaListSpeci(id);
    },
    addTvaActionSpe(data, params, index) {
      this[params][index].listTva.push(data);
    },
    disabledStartDateUpdate(date) {
      if (this.organismeNewToUpdate.date_fin) {
        return (
          this.organismeNewToUpdate.date_fin &&
          moment(date, 'YYYY-MM-DD') <=
            new Date(this.organismeNewToUpdate.date_fin)
        );
      }
      return true;
    },
    disabledEndDateUpdate(date) {
      if (this.organismeNewToUpdate.date_debut) {
        return (
          this.organismeNewToUpdate.date_debut &&
          moment(date, 'YYYY-MM-DD').add(1, 'days') >
            new Date(this.organismeNewToUpdate.date_debut)
        );
      }
      return true;
    },
    disabledStartDate(date) {
      if (this.organismeNewToAdd.date_fin) {
        return (
          this.organismeNewToAdd.date_fin &&
          moment(date, 'YYYY-MM-DD') <=
            new Date(this.organismeNewToAdd.date_fin)
        );
      }
      return true;
    },
    disabledEndDate(date) {
      if (this.organismeNewToAdd.date_debut) {
        return (
          this.organismeNewToAdd.date_debut &&
          moment(date, 'YYYY-MM-DD').add(1, 'days') >
            new Date(this.organismeNewToAdd.date_debut)
        );
      }
      return true;
    },
    disabledStartDateFilter(date) {
      if (this.dateEndForSearch) {
        return (
          this.dateEndForSearch &&
          moment(date, 'YYYY-MM-DD') <= new Date(this.dateEndForSearch)
        );
      }
      return true;
    },
    disabledEndDateFiltre(date) {
      if (this.dateStartForSearch) {
        return (
          this.dateStartForSearch &&
          moment(date, 'YYYY-MM-DD').add(1, 'days') >
            new Date(this.dateStartForSearch)
        );
      }
      return true;
    },
    openModalAjouterOrganisme() {
      this.addOrganismeNew = true;
      this.getAllTvas = [];
    },
    handleModalDelete(parametre) {
      this.parametreSpecToDelete = parametre;
      this.deleteParametreSpec = true;
    },
    changeDisabled(index) {
      this.indexUpdate = index;
      this.showModify = true;
      const elem = document.getElementsByClassName(index);
      for (var i = 0; i < elem.length; i++) {
        elem[i].removeAttribute('disabled');
      }
    },
    async updateParametreSpec(parametre, index) {
      this.parametreToUpdate = parametre;
      this.indexUpdate = index;
      this.showUpdate = false;
      let parametreSpec = {
        id: parametre.id,
        coef_prime_installateur: parseFloat(parametre.coef_prime_installateur),
        tva_coef_prime_installateur: parseFloat(
          parametre.tva_coef_prime_installateur
        ),
        coef_commercial_prime: parametre.coef_commercial_prime,
        tva_coef_commercial_prime: parseFloat(
          parametre.tva_coef_commercial_prime
        ),
        coef_commercial_group: parseFloat(parametre.coef_commercial_group),
        tva_coef_commercial_group: parseFloat(
          parametre.tva_coef_commercial_group
        ),
        coef_surprime_group: parseFloat(parametre.coef_surprime_group),
        tva_coef_surprime_group: parseFloat(parametre.tva_coef_surprime_group),
        coef_commission_gestion_dossier_administrative: parseFloat(
          parametre.coef_commission_gestion_dossier_administrative
        ),
        tva_coef_commission_gestion_dossier_administrative: parseFloat(
          parametre.tva_coef_commission_gestion_dossier_administrative
        )
      };
      const response = await this.updateParametreSpecifique({
        parametre: parametreSpec,
        oldParametre: this.parametreToUpdate
      });
      if (response.success) {
        const elem = document.getElementsByClassName(index);
        elem.forEach(element => {
          element.setAttribute('disabled', '');
        });
        this.showModify = false;
        this.indexUpdate = null;
      }
    },
    async handleDeleteParametreSpec() {
      this.loadingDeleteParametre = true;
      const response = this.deleteParametreSpecifique(
        this.parametreSpecToDelete.id
      );
      if (response) {
        this.loadingDeleteParametre = false;
        this.hideModal('deleteParametreSpec');
      }
    },
    async handleModalParametreSpec() {
      this.updateOrganisme = false;
      this.modalParametreSpec = true;
      await this.fetchAllParametreSpecifique({
        config_id: this.organismeNewToUpdate.id
      });
      this.parametreFixeOrganisme.map(i => {
        i.listTva = this.tvaListSpeci(i.filiale_id.id);
        return i;
      });
    },
    async handleSubmitParametre(parametre, index) {
      this.indexParametre = index;
      let parametreSpec = {
        config_id: this.organismeNewToUpdate.id,
        filiale_id: parametre.filiale_id.id,
        coef_prime_installateur: parametre.coef_prime_installateur,
        tva_coef_prime_installateur: parseFloat(
          parametre.tva_coef_prime_installateur
        ),
        coef_commercial_prime: parametre.coef_commercial_prime,
        tva_coef_commercial_prime: parseFloat(
          parametre.tva_coef_commercial_prime
        ),
        coef_commercial_group: parseFloat(parametre.coef_commercial_group),
        tva_coef_commercial_group: parseFloat(
          parametre.tva_coef_commercial_group
        ),
        coef_surprime_group: parseFloat(parametre.coef_surprime_group),
        tva_coef_surprime_group: parseFloat(parametre.tva_coef_surprime_group),
        coef_commission_gestion_dossier_administrative: parseFloat(
          parametre.coef_commission_gestion_dossier_administrative
        ),
        tva_coef_commission_gestion_dossier_administrative: parseFloat(
          parametre.tva_coef_commission_gestion_dossier_administrative
        )
      };
      const response = await this.addNewParametreSpecfique(parametreSpec);
      if (response.success) {
        this.showUpdate = true;
        this.deleteBlockParametre(index);
        const elem = document.getElementsByClassName('parametre' + index);
        elem.forEach(element => {
          element.setAttribute('disabled', '');
        });
        this.setTvaListSpecific(
          response.data.filiale_id,
          'parametreFixeOrganisme',
          0,
          false
        );

        this.showModify = false;
      }
    },
    deleteBlockParametre(index) {
      this.parametreSpec.splice(index, 1);
    },
    addLineParametreSpec() {
      this.updateOrganisme = false;
      this.parametreSpec.unshift({
        filiale_id: { id: null },
        coef_prime_installateur: this.organismeNewToUpdate
          .coef_prime_installateur,
        tva_coef_prime_installateur: this.organismeNewToUpdate
          .tva_coef_prime_installateur,
        coef_commercial_prime: this.organismeNewToUpdate.coef_commercial_prime,
        tva_coef_commercial_prime: this.organismeNewToUpdate
          .tva_coef_commercial_prime,
        coef_commercial_group: this.organismeNewToUpdate.coef_commercial_group,
        tva_coef_commercial_group: this.organismeNewToUpdate
          .tva_coef_commercial_group,
        coef_surprime_group: this.organismeNewToUpdate.coef_surprime_group,
        tva_coef_surprime_group: this.organismeNewToUpdate
          .tva_coef_surprime_group,
        coef_commission_gestion_dossier_administrative: this
          .organismeNewToUpdate.coef_commission_gestion_dossier_administrative,
        tva_coef_commission_gestion_dossier_administrative: this
          .organismeNewToUpdate
          .tva_coef_commission_gestion_dossier_administrative,
        listTva: []
      });
    },
    async checkFormuleConfigFraisDynamic(type, index) {
      let value =
        type === 'add'
          ? this.organismeNewToAdd.coef_commercial_prime
          : this.organismeNewToUpdate.coef_commercial_prime;
      // type === 'add'
      //   ? (this.configurationToAdd.formules[index].error = null)
      //   : (this.configurationToUpdate.formules[index].error = null);
      const response = await this.checkFormuleConfigFraisDynamicMethod({
        formule: value
      });
      if (response && response.success == false) {
        this.errorFormule = response.error;
      } else {
        this.errorFormule = null;
      }
    },
    CopyFields(payload) {
      var textarea = document.createElement('textarea');
      textarea.textContent = '$' + payload;
      document.body.appendChild(textarea);
      var selection = document.getSelection();
      var range = document.createRange();
      range.selectNode(textarea);
      selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand('copy');
      selection.removeAllRanges();

      document.body.removeChild(textarea);
      this.isTextCopied = true;
      setTimeout(() => {
        this.isTextCopied = false;
      }, 800);
    },
    checkAllFunction() {
      this?.getOrganismeThNew?.map(item => {
        item.check = this.checkAll;
      });
    },
    checkOneFunction(data) {
      data.check == !data.check;
    },
    async handelBlokedRows() {
      let checkesRows = this?.getOrganismeThNew?.filter(
        row => row.check == true
      );
      let ids = [];
      checkesRows.map(item => ids.push(item.id));
      const response = await this.lock_unlock(ids);
      if (response) {
        this.handleFilter();
      }
    },
    resetModal() {
      this.validateToAddconf = true;
      this.loadingDeleteParametre = false;
      this.errorFormule = null;
      this.parametreSpec = [];
      this.organismeNewToAdd = {
        organisme: null,
        group: null,
        coef_prime_installateur: null,
        tva_coef_prime_installateur: 0,
        coef_commercial_prime: 0,
        tva_coef_commercial_prime: null,
        coef_commercial_group: null,
        tva_coef_commercial_group: 20,
        coef_surprime_group: null,
        tva_coef_surprime_group: 20,
        date_debut: null,
        date_fin: null,
        label_commercial_prime: null,
        description__commercial_prime: null,
        label_commercial_group: null,
        description_commercial_group: null,
        label_surprime_group: null,
        description_surprime_group: null,
        label_missing_prime: null,
        description_missing_prime: null,
        label_missing_prime_av: null,
        description_missing_prime_av: null,
        coef_commission_gestion_dossier_administrative: null,
        tva_coef_commission_gestion_dossier_administrative: 20,
        label_commission_gestion_dossier_administrative: null,
        description_commission_gestion_dossier_administrative: null,
        locked: 0
      };
      this.error = null;
      this.errorDupplicate = null;
      this.loading = false;
      this.validateToAddconf = false;
      this.objToSend = {
        organisme_id: null,
        group_id: null,
        date_debut: null,
        date_fin: null,
        id: null
      };
    },
    hideModal(ref) {
      this[ref] = false;
      if (ref == 'modalParametreSpec') {
        this.updateOrganisme = true;
        this.getAllTvas = [];
      }
      if (ref == 'addOrganismeNew') {
        this.$refs.formAdd.reset();
      }
      this.resetModal();
    },
    async hendleSubmitOrganismeNew() {
      if (this.$refs.formAdd.validate()) {
        this.loading = true;
        if (
          this.organismeNewToAdd.date_debut === null ||
          this.organismeNewToAdd.date_debut == ''
        ) {
          this.error = 'date debut is required';
          this.loading = false;
        } else {
          const response = await this.addNewOrganismeThNew(
            this.organismeNewToAdd
          );
          if (response.succes) {
            this.loading = false;
            this.error = null;
            this.errorDupplicate = null;
            this.validateToAddconf = false;
            this.hideModal('addOrganismeNew');
          } else {
            this.error = response.error;
            this.loading = false;
          }
        }
      }
    },
    handleChangeOrganisme() {
      this.handleFilter();
    },
    handleChangeGroup() {
      this.handleFilter();
    },
    handleChangeDate_debut(value) {
      this.dateStartForSearch = value;
      this.handleFilter();
    },
    handleChangeDate_fin(value) {
      this.dateEndForSearch = value;
      this.handleFilter();
    },
    changePerPage() {
      this.page = 1;
      this.handleFilter();
    },
    pagination(paginate) {
      this.page = paginate;
      this.handleFilter();
    },
    handleFilter() {
      this.fetchAllOrganismeThNew({
        per_page: this.perPage,
        page: this.page,
        organisme: this.filterOrganisme,
        group: this.filterGroup,
        date_debut: this.dateStartForSearch,
        date_fin: this.dateEndForSearch
      });
    },
    DupplicateOrganismeNew(item) {
      this.organismeNewToAdd = {
        organisme: item.organisme.id,
        group: item.group.id,
        coef_prime_installateur: item.coef_prime_installateur,
        tva_coef_prime_installateur: item.tva_coef_prime_installateur,
        coef_commercial_prime: item.coef_commercial_prime,
        tva_coef_commercial_prime: item.tva_coef_commercial_prime,
        coef_commercial_group: item.coef_commercial_group,
        tva_coef_commercial_group: item.tva_coef_commercial_group,
        coef_surprime_group: item.coef_surprime_group,
        tva_coef_surprime_group: item.tva_coef_surprime_group,
        date_debut: item.date_debut,
        date_fin: item.date_fin,
        label_commercial_prime: item.label_commercial_prime,
        description__commercial_prime: item.description__commercial_prime,
        label_commercial_group: item.label_commercial_group,
        description_commercial_group: item.description_commercial_group,
        label_surprime_group: item.label_surprime_group,
        description_surprime_group: item.description_surprime_group,
        label_missing_prime: item.label_missing_prime,
        description_missing_prime: item.description_missing_prime,
        label_missing_prime_av: item.label_missing_prime_av,
        description_missing_prime_av: item.description_missing_prime_av,
        coef_commission_gestion_dossier_administrative:
          item.coef_commission_gestion_dossier_administrative,
        tva_coef_commission_gestion_dossier_administrative:
          item.tva_coef_commission_gestion_dossier_administrative,
        label_commission_gestion_dossier_administrative:
          item.label_commission_gestion_dossier_administrative,
        description_commission_gestion_dossier_administrative:
          item.description_commission_gestion_dossier_administrative,
        id: null
      };
      this.tvaList(this.organismeNewToAdd.organisme);

      this.organismeNewToAdd.date_debut = moment(
        this.organismeNewToAdd.date_debut,
        'DD-MM-YYYY'
      ).format('YYYY-MM-DD');
      if (this.organismeNewToAdd.date_fin != null) {
        this.organismeNewToAdd.date_fin = moment(
          this.organismeNewToAdd.date_fin,
          'DD-MM-YYYY'
        ).format('YYYY-MM-DD');
      }
      this.checkErrorsAdd();
      // this.organismeNewToAdd = { ...item };
      this.addOrganismeNew = true;
    },
    handleUpdate(item) {
      this.organismeNewToUpdate = { ...item };
      this.getAllProFilial();
      this.updateOrganisme = true;
      this.tvaList(this.organismeNewToUpdate.organisme.id);
      this.organismeNewToUpdate.date_debut = moment(
        this.organismeNewToUpdate.date_debut,
        'DD-MM-YYYY'
      ).format('YYYY-MM-DD');
      this.organismeNewToUpdate.date_fin =
        this.organismeNewToUpdate.date_fin != null &&
        this.organismeNewToUpdate.date_fin != ''
          ? moment(this.organismeNewToUpdate.date_fin, 'DD-MM-YYYY').format(
              'YYYY-MM-DD'
            )
          : this.organismeNewToUpdate.date_fin;
    },
    deleteOrganismeNew(item) {
      this.selectedOrganisme = item;
      this.deleteOrganismeThNewModal = true;
    },
    async handleSuprimerOrganismeNew() {
      const response = this.deletOrganismeThNew(this.selectedOrganisme);
      if (response) {
        this.hideModal('deleteOrganismeThNewModal');
      }
    },
    async handleSubmitUpdateOrganismeNew() {
      this.loading = true;
      if (
        this.organismeNewToUpdate.date_debut === null ||
        this.organismeNewToUpdate.date_debut == ''
      ) {
        this.error = 'date debut is required';
        this.loading = false;
      } else {
        const response = await this.EditOrganismeTh(this.organismeNewToUpdate);

        if (response) {
          this.hideModal('updateOrganisme');
        } else {
          this.loading = false;
        }
      }
    },
    async checkErrorsAdd() {
      var objToSend = {
        organisme_id: this.organismeNewToAdd.organisme
          ? this.organismeNewToAdd.organisme
          : null,
        group_id: this.organismeNewToAdd.group
          ? this.organismeNewToAdd.group
          : null,
        date_debut: this.organismeNewToAdd.date_debut
          ? this.organismeNewToAdd.date_debut
          : null,
        date_fin: this.organismeNewToAdd.date_fin
          ? this.organismeNewToAdd.date_fin
          : null,
        id: null
      };

      if (objToSend.organisme_id != null && objToSend.group_id != null) {
        const response = await this.checkPeriodOrganism(objToSend);
        if (response.success) {
          this.validateToAddconf = true;
          this.errorDupplicate = null;
        } else {
          this.errorDupplicate = response.errorDupplicate;
          this.validateToAddconf = false;
        }
      }
    },
    tvaList(id) {
      let tva = this.computedListOrganisme.filter(i => i.id == id);
      this.getAllTvas = tva.length > 0 ? tva[0].tva : [];
    },
    tvaListSpeci(id) {
      let tva = this.getProFilials.filter(i => i.id == id);
      return tva.length > 0 ? tva[0].tva : [];
    },
    async checkErrorsUpdate() {
      var objToSend = {
        organisme_id: this.organismeNewToUpdate.organisme.id
          ? this.organismeNewToUpdate.organisme.id
          : null,
        group_id: this.organismeNewToUpdate.group.id
          ? this.organismeNewToUpdate.group.id
          : null,
        date_debut: this.organismeNewToUpdate.date_debut
          ? this.organismeNewToUpdate.date_debut
          : null,
        date_fin: this.organismeNewToUpdate.date_fin
          ? this.organismeNewToUpdate.date_fin
          : null,
        id: this.organismeNewToUpdate.id ? this.organismeNewToUpdate.id : null
      };

      if (objToSend.organisme_id != null && objToSend.group_id != null) {
        const response = await this.checkPeriodOrganism(objToSend);

        if (response.success) {
          this.validateToAddconf = true;
          this.errorDupplicate = null;
        } else {
          this.errorDupplicate = response.errorDupplicate;
          this.validateToAddconf = false;
        }
      }
    }
  },
  components: { tva: () => import('@/views/component/tva/tva.vue') },
  async mounted() {
    this.getSettingFilialeTh();
    this.getAllNumColums();
    this.getFilialsOfConnectedResponsable({ minimum: 'yes' });
    // this.fetchAllOrganismeThNew();
    this.handleFilter();
  }
};
</script>

<style lang="scss" scoped>
.content-btn-params-specifique {
  margin: auto;
  justify-content: center;
}
.table-gestion-content {
  max-height: calc(100vh - 253px) !important;
  height: calc(100vh - 266px) !important;
}
.style-half-circle-loder-organisme {
  margin-left: -9% !important;
  padding-top: 19% !important;
}
.messageError {
  font-size: 13px;
  font-weight: 600;
}
.gestion-organisme-new-th
  .organisme-new-body
  .organisme-details
  .custom-table-style {
  padding: 5px;
  max-height: calc(100vh - 310px) !important;
  height: calc(100vh - 3100px) !important;
}
.parametre_block {
  color: #000;
  position: relative;
  overflow: visible;
  font-size: 12px;
  margin-top: 5px;
  border: 1px solid rgba(108, 117, 125, 0.3215686275);
  padding: 11px;
  background-color: #fff;
  width: 100%;
  border-radius: 4px;
  margin: 0px auto;

  .button-add-prop-filiale-th {
    display: flex;
  }

  .edit-action {
    width: 5%;
    display: flex;
    font-size: 7px;
    justify-content: space-between;
    // height: 80px;
    align-content: center;
    align-items: center;
    margin-right: 2%;

    .action-icon {
      cursor: pointer;
    }

    .validate {
      color: green;
    }

    .cancel {
      color: #ab2525;
    }
  }
}
.gestion-organisme-new-th {
  height: 100%;
  .tabs-organisme {
    width: 25%;
    margin: 5px 15px;
    .custom-list-group {
      .list-group-item {
        justify-content: space-between;
        color: #515356;
        display: block;
        font-weight: 600;
        .list-item {
          justify-content: space-between;
        }
      }
    }
    .title {
      color: #515356;
      display: block;
      font-weight: 600;
      margin: 0px 0px 10px 0px;
    }
    .organisme-labels {
      display: flex;
      justify-content: space-between;
    }
    .trash-style {
      margin-left: 5px;
      color: #dc3545;
    }
  }

  .organisme-new-body {
    display: flex;
    margin: 5px 15px;

    height: calc(100vh - 194px) !important;
    .organisme-details {
      width: 100%;
      .title {
        color: #515356;
        display: block;
        font-weight: 600;
        margin: 5px;
      }
      .input-modal-champ {
        input {
          border-radius: 5px;
        }
      }
      .custom-table-style {
        padding: 5px;
        max-height: calc(100vh - 280px) !important;
        height: calc(100vh - 280px) !important;
        .button-succes-style,
        .button-danger-style,
        .btn-secondary {
          border-radius: 6px;
          padding: 3px 5px;
          font-size: 12px;
          width: 25px;
          height: 25px;
          margin: 2px;
        }
      }
    }
  }
  .ligne-box-setting-header {
    margin-top: 1%;
  }
}
.label-box-style {
  width: 65px;
}
.radues-locked-icon {
  border-radius: 30px;
}
.box-all-filter .mx-datepicker {
  width: 110px;
}

.form-modal-custom-style .form-group .d-block {
  margin-left: 0px !important;
}
.loadingFetch {
  width: 16px;
  height: 16px;
  font-size: 10px;
}
.row-msg {
  margin-top: -18px;
  .margin-auto {
    margin: auto !important;
  }
}
</style>
<style lang="scss">
.check-body-organisme {
  margin-top: 0px !important;
  padding-top: 0px !important;
  margin-left: 11px;
}
.check-header-organisme {
  margin-left: 11px;
}
.select-organisme {
  .v-input__slot {
    margin-bottom: 0px !important;
  }
}
.v-dialog.custom-vuetify-dialog-gestion-organisme.v-dialog--active.v-dialog--persistent {
  position: absolute !important;
  height: 100% !important;
}
.style-half-circle-loder {
  padding-top: 0px !important;
  background-color: #fff !important;
}
.cutsom-modal-bootstrap-filiale-th .modal-dialog {
  top: 0%;
  max-width: 95%;
  width: 95%;
}
.box-all-filter .mx-input {
  height: 31px !important;
  font-size: 11px !important;
  border: 0px solid #ccc;
  border-radius: 0px 18px 18px 0px;
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 1100px;
  }
}
.table-organisme-new td,
.table-rapport-style th {
  padding: 7px !important;
}
.textarea-societe {
  line-height: 0.9;
}
</style>
